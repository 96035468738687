import * as React from 'react';

import { Helmet } from 'react-helmet';

export const GoogleNoIndex: React.FunctionComponent = () => {
  return (
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
  );
};
