declare global {
  interface Window {
    gtag: any;
  }
}

function trackEvent(action: string, category: string, label: string): void {
  if (window.gtag) {
    window.gtag(`event`, action, {
      event_category: category,
      event_label: label,
    });
  } else {
    // When in develop mode there is no gtag
    console.log('TRACKING', {
      action: action,
      event_category: category,
      event_label: label,
    });
  }
}

export function trackOverassessedClick(): void {
  trackEvent('go_to_search', 'Overassessed', 'Analyze My Property Value');
}

export function trackGetStartedClick(): void {
  trackEvent('go_to_search', 'GetStarted', 'Get Started');
}

export function trackPurchase(orderId: string, couponCode: string): void {
  if (window.gtag) {
    window.gtag('event', 'purchase', {
      transaction_id: orderId,
      value: couponCode ? 80.0 : 100.0,
      currency: 'USD',
      coupon: couponCode,
      items: [
        {
          item_id: '1001',
          item_name: 'Your Custom Playbook',
          coupon: couponCode,
          currency: 'USD',
          discount: couponCode ? 20.0 : 0.0,
          index: 0,
          price: 100.0,
          quantity: 1,
        },
      ],
    });
  } else {
    // When in develop mode there is no gtag
    console.log('TRACK PURCHASE', {
      transaction_id: orderId,
      value: couponCode ? 80.0 : 100.0,
      currency: 'USD',
      coupon: couponCode,
      items: [
        {
          item_id: '1001',
          item_name: 'Your Custom Playbook',
          coupon: couponCode,
          currency: 'USD',
          discount: couponCode ? 20.0 : 0.0,
          index: 0,
          price: 100.0,
          quantity: 1,
        },
      ],
    });
  }
}
