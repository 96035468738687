import * as React from 'react';

import classNames from 'classnames';

type FooterProps = {
  fixed?: boolean;
};

export const Footer: React.FunctionComponent<FooterProps> = (props: FooterProps) => {
  return (
    <footer id="footer-1" className={classNames({ 'bg-footer division pt-0 pb-20 mt-0': true, 'position-fixed bottom-0 start-0 end-0': props.fixed ?? false })}>
      {/** classNames({ 'bg-snow footer division': true, 'position-fixed bottom-0 start-0 end-0': props.fixed ?? false })*/}
      <div className="container">
        <div className="pt-20">
          <div className="row row-cols-1 row-cols-md-2 d-flex align-items-center">
            <div className="col">
              <div className="footer-copyright">
                <p>© {new Date().getFullYear()} Property Tax Playbook. All Rights Reserved</p>
              </div>
            </div>
            <div className="col">
              {/* <ul className="bottom-footer-list text-secondary text-end">
                <li className="first-li">
                  <p>
                    <a href="/">Facebook</a>
                  </p>
                </li>
                <li>
                  <p>
                    <a href="/">Twitter</a>
                  </p>
                </li>
                <li>
                  <p>
                    <a href="/">LinkedIn</a>
                  </p>
                </li>
                <li className="last-li">
                  <p>
                    <a href="/">Dribbble</a>
                  </p>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
