import * as React from 'react';

import { Helmet } from 'react-helmet';

type PageTitleProps = {
  subtitle?: string;
};

export const PageTitle: React.FunctionComponent<PageTitleProps> = (props: PageTitleProps) => {
  return (
    <Helmet>
      <html lang="en" />
      <title>Property Tax Playbook{props.subtitle ? ' - ' + props.subtitle : ''}</title>
      <meta
        name="description"
        content="Property tax protest evidence and appeal preparation to support assessment value reductions for residential property owners filing their own appeals. Our custom-built playbooks encompass everything needed to file a successful property tax appeal to make the process easy for individual homeowners."
      />
      {/* <link rel="sitemap" type="text/xml" href="/sitemap/sitemap-index.xml"></link> */}
    </Helmet>
  );
};
