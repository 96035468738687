import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import { trackGetStartedClick } from '../../utils/Tracking';

type NavMenuProps = {
  fixed?: boolean;
  noNav?: boolean;
};

export const NavMenu: React.FunctionComponent<NavMenuProps> = (props: NavMenuProps) => {
  const [toggle, setToggle] = React.useState(false);

  const toggleFun = () => {
    setToggle(!toggle);
    document.querySelector('body').classList.toggle('wsactive');
  };

  const scroll = () => {
    let offset = window.scrollY;
    const sticky = document.querySelector('.wsmainfull');
    if (sticky) {
      if (offset > 80) {
        sticky.classList.add('scroll');
      } else {
        sticky.classList.remove('scroll');
      }
    }
  };

  React.useEffect(() => {
    if (props.fixed) {
      const sticky = document.querySelector('.wsmainfull');
      sticky.classList.add('scroll');
    } else {
      window.addEventListener('scroll', scroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <header id="header" className={`header tra-menu navbar-light`}>
      <div className="header-wrapper">
        {/* MOBILE HEADER */}
        <div className="wsmobileheader clearfix">
          <span className="smllogo">
            <a href="/" className="logo-white">
              <StaticImage src="../../images/ptp-logo.png" height={100} alt="logo image" placeholder="none" />
            </a>
            {/* <img src="/images/logo-01.png" alt="mobile-logo" /> */}
          </span>
          {!props.noNav && (
            <a href="#top" id="wsnavtoggle" onClick={() => toggleFun()} className="wsanimated-arrow">
              <span></span>
            </a>
          )}
        </div>

        <div className="wsmainfull menu clearfix">
          <div className="wsmainwp clearfix">
            {/* HEADER LOGO */}
            <div className="desktoplogo">
              <a href="/" className="logo-black">
                <StaticImage src="../../images/ptp-logo.png" height={65} alt="logo image" placeholder="none" />
              </a>
            </div>
            <div className="desktoplogo">
              <a href="/" className="logo-white">
                <StaticImage src="../../images/ptp-logo-white.png" height={65} alt="logo image" placeholder="none" />
              </a>
            </div>
            {!props.noNav && (
              <nav className="wsmenu clearfix">
                <div className="overlapblackbg" onClick={() => toggleFun()} />

                <ul className={`wsmenu-list nav-orange-red-hover`}>
                  <li className="nl-simple">
                    <a href="/#value">Value</a>
                  </li>
                  <li className="nl-simple">
                    <a href="/#benefits">Benefits</a>
                  </li>
                  <li className="nl-simple">
                    <a href="/#three-step">3 Steps</a>
                  </li>
                  {/* HEADER BUTTON */}
                  <li className="nl-simple">
                    <a href="/search" className={`btn btn-yellow yellow-hover last-link`} onClick={() => trackGetStartedClick()}>
                      Get Started
                    </a>
                  </li>
                  {/* HEADER SOCIAL LINKS 													
									<li class="nl-simple white-color header-socials ico-20 clearfix" >
										<span><Link href="#" class="ico-facebook"><span class="flaticon-facebook"></span></Link></span>
										<span><Link href="#" class="ico-twitter"><span class="flaticon-twitter"></span></Link></span>
										<span><Link href="#" class="ico-instagram"><span class="flaticon-instagram"></span></Link></span>
										<span><Link href="#" class="ico-dribbble"><span class="flaticon-dribbble"></span></Link></span>	
									</li> */}
                </ul>
              </nav>
            )}
          </div>
        </div>
      </div>
      {props.fixed && <div style={{ height: 100 }}></div>}
    </header>
  );
};
